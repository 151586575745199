import { Link, Navigate, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, H4, Image, P } from "../AbstractElements";
import { dynamicImage } from "../Service";
import { CreateAccount, DoNotAccount, EmailAddress, ForgotPassword, Href, Password, RememberPassword, SignIn, SignInAccount, SignInWith } from "../utils/Constant";
import { useState } from "react";
import { toast } from "react-toastify";
import SocialApp from "./SocialApp";
import { loginAction } from "../Api/auth";
import { useAppDispatch, useAppSelector } from "../ReduxToolkit/Hooks";
import { setUser } from "../ReduxToolkit/Reducers/UserSlice";

const Login = () => {

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const loginHandle = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { user, response } = await loginAction({ email, password })
    if (user !== undefined) {
      dispatch(setUser(user))
    }
    else {
      const _response: any = response
      if (_response?.response)
        toast.error(_response.response.data.message)
      else
        toast.error("Login failed!")
    }
  };

  const loggedIn = useAppSelector(state => state.user.loggedIn)
  return !loggedIn ? (

    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={Href}>
                  <h2 className='text-white ms-1 font-primary' style={{ fontSize: '32px' }}>BePersonal AI</h2>
                </Link>
              </div>
              <div className="login-main">
                <Form className="theme-form" onSubmit={(e) => loginHandle(e)} >
                  <H3>{SignInAccount}</H3>
                  <P>{"Enter your email & password to login"}</P>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input type="email" required placeholder="john@smith.com" value={email} name="email" onChange={(event) => setEmail(event.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <div className="form-input position-relative">
                      <Input type={show ? "text" : "password"} placeholder="*********" onChange={(event) => setPassword(event.target.value)} value={password} name="password" />
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <div className="checkbox p-0">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" htmlFor="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <Link to={`/forget-password`}  >
                      {ForgotPassword}
                    </Link>
                    <div className="text-end mt-3">
                      <Btn color="primary" block className="w-100" >
                        {SignIn}
                      </Btn>
                    </div>
                  </FormGroup>
                  <P className="mt-4 mb-0 text-center">
                    {DoNotAccount}
                    <Link className="ms-2" to={`/register`} >
                      {CreateAccount}
                    </Link>
                  </P>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  ) :
    <Navigate to="/?from=login" />
    ;
};

export default Login;
