import { Container, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { H3, P, H5 } from '../../../../AbstractElements';


const InstructionSection = () => {

  return (
    <Container fluid className="p-0">
      <Row className="m-0 instruction-content">
        <Col xs="12" className="p-0">
          <div className="instruction-section">
            <H3 className='mt-3 mb-2'>Instructions</H3>
            <P>Here's everything that you need to know about recording template videos on your own</P>
            <H5 className='my-3'>Record your video as if you were recording them for a single person.</H5>
            <H5 className='mb-3'>Make sure your face is clearly visible. For example, if you are waving or making any sort of hand gestures, be sure to do that out to the side.</H5>

            <H5 className='mb-3'>Allow short, natural breaks between words before and after pronouncing the variable.</H5>

            <H5 className='mb-3'>Try not to move your head too much.</H5>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default InstructionSection