import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import UploadTemplateForm from './UploadTemplateForm'
import InstructionSection from './InstructionSection'

const TemplateUploadContainer = () => {
  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <InstructionSection />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <UploadTemplateForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default TemplateUploadContainer