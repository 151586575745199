import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../ReduxToolkit/Hooks';
import { Form } from 'reactstrap';
import ButtonSection from './ButtonSection';
import VariableSection from './VariableSection';
import { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CampaignTemplateEditor from './CampaignTemplateEditor';
import { CampaignTemplateFormType } from '../../../Types/Campaign/CampaignType';

const CreateCampaignForm = () => {
  const navigate = useNavigate();
  const [variables, setVariables] = useState<string[]>([]);
  const [campaignTemplate, setCampaignTemplate] = useState("");
  const dispatch = useAppDispatch();

  const generateVideo = async () => {
    if (variables.length && campaignTemplate) {
      navigate("/campaign/list");
    }
  };

  return (
    <>
      <Form className="theme-form">
        <Row>
          <Col lg={3}>
            <VariableSection variables={variables} setVariables={setVariables} />
          </Col>
          <Col lg={9}>
            <Card>
              <CardBody>
                <CampaignTemplateEditor onChange={setCampaignTemplate}/>
                <ButtonSection onSubmit={generateVideo} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default CreateCampaignForm