import { Col, Row } from 'reactstrap'
import { Btn } from '../../../AbstractElements'
import { useSelector } from 'react-redux'
import { RootState } from '../../../ReduxToolkit/Store'

interface ButtonSectionType {
  onSubmit: () => void
}
const ButtonSection = ({ onSubmit }: ButtonSectionType) => {

  return (
    <Row>
      <Col>
        <div className="text-end">
          <Btn color="success mt-3" onClick={onSubmit}>
            {"Create a campaign"}
          </Btn>
        </div>
      </Col>
    </Row>
  )
}

export default ButtonSection