import GenerateImageContainer from "../../../Component/PersonalImage/GenImage/GenerateImage"

const GenImage = () => {
  return (
    <div className='page-body'>
      <GenerateImageContainer />
    </div>
  )
}

export default GenImage