import * as Yup from "yup";
import { TemplateType, TemplateFormType } from "../../../Types/PersonalVideo/TemplateList/TemplateList";

export const TemplateData: TemplateType[] = [

]

export const templateInitialValue: TemplateFormType = {
    name: "",
};

export const templateValidation = Yup.object().shape({
    name: Yup.string().required("Video name is required"),
  });