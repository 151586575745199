import VideoCreateContainer from "../../../../Component/PersonalVideo/GenVideo/VideoCreate/VideoCreate"

const CreateVideo = () => {
  return (
    <div className='page-body'>
      <VideoCreateContainer />
    </div>
  )
}

export default CreateVideo