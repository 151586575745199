
import ForgetPassword from "../Authentication/ForgetPassword";
import Login from "../Authentication/Login";
import Register from "../Authentication/Register";
import ResetPassword from "../Authentication/ResetPassword";

export const authRoutes = [
  { path: `/register`, Component: <Register /> },
  { path: `/login`, Component: <Login /> },
  { path: `/forget-password`, Component: <ForgetPassword /> },
  { path: `/reset-password/:token`, Component: <ResetPassword /> },
];
