import { useState } from 'react'
import { useAppSelector } from '../../../../../ReduxToolkit/Hooks';
import { Card, Row } from 'reactstrap';
import HoverButtons from './HoverButtons';
import { TemplateType } from '../../../../../Types/PersonalVideo/TemplateList/TemplateList';
import TemplateDetails from './TemplateDetails';

const TemplateGrid = () => {
    const { templateList } = useAppSelector((state) => state.template);
    const { listView, colClass } = useAppSelector((state) => state.filterData);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [dataId, setDataId] = useState<undefined | string>();
    return (
      <div className={`product-wrapper-grid ${listView ? "list-view" : ""}`}>
        <Row>
          {templateList &&
            templateList.map((item: TemplateType, index: number) => {
              return (
                <div id="gridId" className={`${colClass} ${listView ? "col-xl-12" : ""}`} key={index}>
                  <Card>
                    <div className="product-box">
                      <div className="product-img">
                        {/* <Image className="img-fluid" src={dynamicTemplate(`uploads/video/${item.filename}`)} alt="product" /> */}
                        <HoverButtons setDataId={setDataId} setOpenModal={setOpenModal} item={item} />
                      </div>
                      <TemplateDetails item={item} />
                    </div>
                  </Card>
                </div>
              );
            })}
        </Row>
      </div>
    )
}

export default TemplateGrid