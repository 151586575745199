import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap'
import DataTable from "react-data-table-component";
import { useMemo, useState } from 'react';
import { SearchTableButton } from '../../../utils/Constant';
import { campaignListTableData, campaignListTableDataColumn } from '../../../Data/Campaign/CampaignList';
import { Btn } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';

const CampaignListContainer = () => {
  const [filterText, setFilterText] = useState("");

  const filteredItems = campaignListTableData.length ? campaignListTableData.filter((item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) : [];
  const navigate = useNavigate()
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="dataTables_filter d-flex align-items-center">
        <Label className="me-2">{SearchTableButton}:</Label>
        <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)} type="search" value={filterText} />
        <Btn color="success" className="ms-3" style={{ minWidth: "160px" }} onClick={() => {
          navigate("/campaign/create_new");
        }}>{"New Campaign"}</Btn>
      </div>
    );
  }, [filterText]);

  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <div className="list-product">
                <div className="table-responsive">
                  <DataTable data={filteredItems} columns={campaignListTableDataColumn} striped highlightOnHover pagination subHeader subHeaderComponent={subHeaderComponentMemo} />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default CampaignListContainer