import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { Form, Formik } from 'formik';
import ButtonSection from './ButtonSection'; import { useState } from 'react';
import TemplateNameSection from './TemplateNameSection';
import UploadTemplateFile from './UploadTemplateFile';
import { P } from '../../../../AbstractElements';
import { createTemplateAPI, uploadTemplateAPI, uploadThumbnailAPI } from '../../../../Api/template';
import { isVideoFile } from '../../../../utils';
import { TemplateFormType } from '../../../../Types/PersonalVideo/TemplateList/TemplateList';
import { templateValidation, templateInitialValue } from '../../../../Data/PersonalVideo/TemplateList/TemplateList';
import { addCreatedTemplate } from '../../../../ReduxToolkit/Reducers/TemplateSlice';
import { toast } from 'react-toastify';

const UploadTemplateForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isUploaded, setIsUploaded] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const [imageData, setImageData] = useState<string | null>(null);

  const templateUpload = async (values: TemplateFormType) => {
    if (values.name && file) {
      if (isVideoFile(file) && imageData) {
        const _file = new FormData()
        file ? _file.append("file", file, file.name) : _file.append("file", "")

        const blob = await fetch(imageData).then((res) => res.blob());
        const imageFile = new File([blob], "thumbnail.jpg", { type: "image/jpeg" });
        const _imageFile = new FormData()
        imageData ? _imageFile.append("file", imageFile, imageFile.name) : _imageFile.append("file", "");

        const thumbnail = await uploadThumbnailAPI(_imageFile)
        const fileName = await uploadTemplateAPI(_file);
        const _template = await createTemplateAPI({
          filename: fileName,
          name: values.name,
          thumbnail: thumbnail
        })

        if (_template) {
          toast.success("Successfully uploaded the video template!", {
            position: "top-right",
            autoClose: 3000,
            closeOnClick: true,
            theme: "light",
          });
        }
        dispatch(addCreatedTemplate(_template))
        navigate('/template/list')
      }
    }
    else if (!file) {
      setIsUploaded(false)
    }
  };

  return (
    <Formik initialValues={templateInitialValue} validationSchema={templateValidation} onSubmit={templateUpload}>
      {() => (
        <Form className="theme-form">
          <TemplateNameSection />
          <UploadTemplateFile file={file} setFile={setFile} isUploaded={isUploaded} setIsUploaded={setIsUploaded} setImageData={setImageData} />
          <ButtonSection />
        </Form>
      )}
    </Formik>
  )
}

export default UploadTemplateForm