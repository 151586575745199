import { MenuItem } from "../../Types/Layout/SidebarType";


export const MenuList: MenuItem[] = [
  {
    title: "General",
    lanClass: "lan-1",
    Items: [
      {
        title: "Dashboard",
        icon: "home",
        path: `/`,
        type: "link",
        lanClass: "lan-3",
        
      },

    ]
  },
  {
    title: "Applications",
    lanClass: "lan-8",
    Items: [
      {
        title: "Personalized Images",
        id: 3,
        icon: "gallery",
        type: "link",
        path: `/image/list`,
      },
      {
        title: "Pricing",
        id: 4,
        icon: "ecommerce",
        type: "link",
        path: `/pricing/upgrade-plan`,
        active: false,
      },
    ]
  },
  // {
  //   title: "Personalized Videos",
  //   lanClass: "lan-8",
  //   Items: [
  //     {
  //       title: "Generate Videos",
  //       id: 3,
  //       icon: "project",
  //       type: "sub",
  //       active: false,
  //       children: [
  //         { path: `/video/list`, type: "link", title: "Video-List" },
  //         { path: `/video/create_new`, type: "link", title: "Create New" },
  //       ],
  //     },
  //     {
  //       title: "Video Template",
  //       id: 4,
  //       icon: "file",
  //       type: "sub",
  //       active: false,
  //       children: [
  //         { path: `/template/list`, type: "link", title: "Template-List" },
  //         { path: `/template/upload_new`, type: "link", title: "Upload template" },
  //       ],
  //     },

  //   ]
  // },

];