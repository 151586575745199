import { Container, Row } from "reactstrap";
import UserInfo from "./UserInfo/UserInfo";
import OpeningOfLeaflets from "./OpeningOfLeaflets/OpeningOfLeaflets";
import ShiftsOverview from "./ShiftsOverview/ShiftsOverview";
import CustomerTransaction from "./CustomerTransaction/CustomerTransaction";
import UserNotifications from "./UserNotifications/UserNotifications";
import UpcomingAppointments from "./UpcomingAppointments/UpcomingAppointments";
import ActiveMembers from "./ActiveMembers/ActiveMembers";
import SalesByProduct from "./SalesByProduct/SalesByProduct";
import SalesStatistic from "./SalesStatistic/SalesStatistic";
import ProjectsTable from "./ProjectsTable/ProjectsTable";
import ProjectStatus from "./ImageStatus/ImageStatus";
import { useAppSelector } from "../../ReduxToolkit/Hooks";
import UsersTable from "./UsersTable/UsersTable";
import SubscribeAlert from "../Pricing/Alert";


const DashboardContainer = () => {
  const { email } = useAppSelector(state => state.user)

  return (
    <Container fluid className="default-dashboard dashboard-2">
      <Row className="widget-grid">
        <SubscribeAlert />

        <UserInfo />
        <ProjectStatus />
        {email.toLowerCase() === 'hey@bepersonal.ai' && <UsersTable />}
        {/* <OpeningOfLeaflets /> */}
        {/* <ShiftsOverview /> */}
        {/* <ProjectStatus/>
        <CustomerTransaction />  */}
        {/* <ProjectsTable /> */}
        {/* <UserNotifications />
        <UpcomingAppointments />
        <ActiveMembers />
        <SalesStatistic />
        <SalesByProduct /> */}
      </Row>
    </Container>
  );
};

export default DashboardContainer;
