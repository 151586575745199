import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { Container } from 'reactstrap';
import TemplateGrid from './TemplateGrid/TemplateGrid';

const TemplatesContainer = () => {
    const { sideBarOn } = useAppSelector((state) => state.filterData);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
    }, []);
  return (
    <Container fluid className={`product-wrapper ${sideBarOn ? "sidebaron" : ""}`}>
      <div className="product-grid">
        <TemplateGrid />
      </div>
    </Container>
  )
}

export default TemplatesContainer