import { Card, CardBody, Col, Row } from 'reactstrap';
import CommonCardHeader from '../../CommonElements/CommonCardHeader/CommonCardHeader';
import { Btn, H4, LI, UL } from '../../AbstractElements';
import { BecomeMembers } from '../../utils/Constant';
import { createCheckoutSession, updatePlanAction } from '../../Api/subscription';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../ReduxToolkit/Hooks';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { setUser } from '../../ReduxToolkit/Reducers/UserSlice';

const plans = [
  { id: 'free', name: 'Free', price: '0', limit: 1, tags: 0 },
  { id: 'price_1PnnSKKUAhug29f3KErSTgg2', name: 'Standard', price: '69', limit: 3, tags: 3000 },
  { id: 'price_1PnnSoKUAhug29f3SWehTv8X', name: 'Business', price: '149', limit: 5, tags: 6000 },
  { id: 'price_1PnnT2KUAhug29f3ANBUW0G5', name: 'Premium', price: '299', limit: 10, tags: 10000 },
];

const BecomeMember = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch()

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const subscriptionStatus = params.get('subscription');
    if (subscriptionStatus === 'successed') {
      toast.success('Subscription upgraded successfully!');
    } else if (subscriptionStatus === 'cancelled') {
      toast.error('Subscription upgrade cancelled.');
    }
  }, [location.search]);

  const handleUpgrade = async (priceId: any) => {
    try {
      const checkoutUrl = await createCheckoutSession(priceId);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error during purchase:', error);
    }
  };
  const handleDownGrade = async (priceId: any, limit: any) => {
    try {
      const response = await updatePlanAction(priceId);
      console.log(response)
      if (response.status = "success") {
        dispatch(setUser({ active_images_limit: limit }))
        toast.success("Subscription downgrade successfully")
      }
      // Optionally, you can update the state to reflect the downgrade immediately
    } catch (error) {
      console.error('Error during downgrade:', error);
    }
  };
  return (
    <Card>
      <CommonCardHeader title={"Upgrade Plan"} />
      <CardBody className="pricing-block">
        <Row>
          {plans.map((item, index) => (
            <Col lg="3" sm="6" className="box-col-3" key={index}>
              <div className="pricingtable">
                <div className="pricingtable-header">
                  <H4 className="title">{item.name}</H4>
                </div>
                <div className="price-value">
                  <span className="currency">$</span>
                  <span className="amount">{item.price}</span>
                  <span className="duration">/mo</span>
                </div>
                <UL className="pricing-content simple-list">
                  <LI>Up to {item.limit} active images</LI>
                  {item.tags>0? <LI>Up to {item.tags} tags to be used</LI>:<LI>No tags to be used</LI>}

                  {user.active_images_limit === item.limit && user.active_images_limit !== 1 && (
                    <LI>Expiry Date: {new Date(user.expire_date).toLocaleDateString()}</LI>
                  )}
                </UL>
                <div className="pricingtable-signup">
                  {user.active_images_limit === item.limit ? (
                    <Btn size="lg" color="secondary" disabled>
                      Current Plan
                    </Btn>
                  ) : (
                    <Btn size="lg" color="primary" onClick={() => {
                      if (user.active_images_limit < item.limit)
                        handleUpgrade(item.id)
                      else handleDownGrade(item.id, item.limit)
                    }
                    }>
                      {user.active_images_limit < item.limit ? "Upgrade" : "DownGrade"}
                    </Btn>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default BecomeMember;
