import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Loader = () => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);
  const query = useQuery();
  const prevRoute = query.get('from');
  console.log(prevRoute)
  return (
    <>
      {show && (
        <div className="loader-wrapper" style={{ width: !prevRoute ? "calc(100% - 252px)" : "100%", marginLeft:!prevRoute ?  "252px" :"0px"}}>
          <div className="loader loader-1">
            <div className="loader-outter" />
            <div className="loader-inner" />
            <div className="loader-inner-1" />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
