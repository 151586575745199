import GenVideosContainer from "../../../../Component/PersonalVideo/GenVideo/GenVideoList/GenVideoList"

const GenVideos = () => {
  return (
    <div className='page-body'>
      <GenVideosContainer />
    </div>
  )
}

export default GenVideos