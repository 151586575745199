import { Provider } from "react-redux";
import RouterData from "./Routes";
import { ToastContainer } from "react-toastify";
import Store from "./ReduxToolkit/Store";
import { parseJwt, setAxiosConfig, setToken } from "./utils";
import axios from "axios";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./ReduxToolkit/Hooks";
import { setUser } from "./ReduxToolkit/Reducers/UserSlice";
import { getTemplateAPI } from "./Api/template";
import { setTemplate } from "./ReduxToolkit/Reducers/TemplateSlice";
import { useNavigate } from "react-router-dom";
import { getOwnStatsAction } from "./Api/auth";

setAxiosConfig(axios, Store);
const token = localStorage.getItem("token")

const App = () => {
  const dispatch = useAppDispatch()
  const loggedIn = useAppSelector(state => state.user.loggedIn)

  const setTemplateStore = async () => {
    const videos = await getTemplateAPI();
    dispatch(setTemplate((videos)))
  }

  const getUserStats = async () => {
    try {
      const response = await getOwnStatsAction();
      if (response.status === 'success') {
        dispatch(setUser({
          personalized_images_count: response.payload.personalized_images_count,
          active_images_limit: response.payload.active_images_limit,
          expire_date: response.payload.expire_date,
          tags_used : response.payload.tags_used,
          total_views: response.payload.total_views
        }))
      }

    } catch (error) {
      console.log("error")
    }
  }

  useEffect(() => {
    if (!token) return
    const user = parseJwt(token)
    setToken(token || "")
    dispatch(setUser(user))
  }, [dispatch])

  useEffect(() => {
    if (!loggedIn) return
    setTemplateStore()
    getUserStats()
  }, [loggedIn])

  return (
    <>
      <Provider store={Store} >
        <RouterData />
        <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
