import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import BecomeMember from './BecomeMember'
import { useAppSelector } from '../../ReduxToolkit/Hooks'
import { Link } from 'react-router-dom'
import { H4 } from '../../AbstractElements'
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const reviews = [
  `"We saw an immediate lift in our reply rates, close to a 80% lift!" - Parker Burr, Flagship'`,
  `"This is a must for anyone sending emails. You can get so creative with this and really draw someone's attention."  - Will Caldwell, SnapNHD`,
  `"Increased our CTR by about 37% across the board. I am impressed." - Riley Stricklin, Lume Cube`
]

const Reviews = () => {
  return (
    <Card>
      <CardBody>
        <Row className='text-center'>
          <Slide easing="ease">
            {reviews.map((slide: any, index: any) => {
              return (
                <Col sm="12" className='mb-4'>
                  <H4 className='mt-5 mb-4'>{slide}</H4>
                  <span style={{ fontSize: '16px' }}>
                    <i className="fa fa-star font-warning me-1"></i>
                    <i className="fa fa-star font-warning me-1"></i>
                    <i className="fa fa-star font-warning me-1"></i>
                    <i className="fa fa-star font-warning me-1"></i>
                    <i className="fa fa-star font-warning me-1"></i>
                  </span>
                </Col>
              );
            })}
          </Slide>

          {/* <Col sm="12">
            <H4>"This is a must for anyone sending emails. You can get some creative with this and really draw someone's attention."  - Will Caldwell, SnapNHD</H4>
            <span style={{ fontSize: '16px' }}>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
            </span>
          </Col>
          <Col sm="12">
            <H4>"Increased our CTR by about 37% across the board. I am impressed." - Riley Stricklin, Lume Cube</H4>
            <span style={{ fontSize: '16px' }}>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
              <i className="fa fa-star font-warning me-1"></i>
            </span>
          </Col> */}

        </Row>
      </CardBody>
    </Card>
  )
}

export default Reviews