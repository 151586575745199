import { Link } from 'react-router-dom'
import { H4, P } from '../../../../../AbstractElements'
import { CommonTemplateInterFace } from '../../../../../Types/PersonalVideo/TemplateList/TemplateList'


const TemplateDetails = ({item}: CommonTemplateInterFace) => {
  return (
    <div className="product-details">
      <Link to={`${process.env.PUBLIC_URL}/ecommerce/product_page`}>
        <H4>{item.name}</H4>
      </Link>
      <P>{item.filename}</P>
      <div className="product-price">
        {item.id} 
      </div>
    </div>
  )
}

export default TemplateDetails