import CreateVideo from "../Pages/PersonalVideo/GenVideo/CreateVideo/CreateVideo";
import GenVideos from "../Pages/PersonalVideo/GenVideo/GenVideoList/GenVideoList";
import TemplateList from "../Pages/PersonalVideo/Template/TemplateList/TemplateList";
import UploadTemplate from "../Pages/PersonalVideo/Template/UploadTemplate/UploadTemplate";
import Dashboard from "../Pages/Dashboard";
import CampaignList from "../Pages/Campaign/CampaignList/CampaignList";
import CreateCampaign from "../Pages/Campaign/CreateCampaign/CreateCampaign";
import GridImage from "../Pages/PersonalImage/GridImage/VideoList";
import GenImage from "../Pages/PersonalImage/GenImage/GenImage";
import Pricing from "../Pages/Pricing/Pricing";

const routes = [
  { path: "", Component: <Dashboard /> },
  { path: "/video/create_new", Component: <CreateVideo /> },

  { path: "/video/list", Component: <GenVideos /> },
  { path: "/template/upload_new", Component: <UploadTemplate /> },
  { path: "/template/list", Component: <TemplateList /> },
  { path: "/campaign/list", Component: <CampaignList /> },
  { path: "/campaign/create_new", Component: <CreateCampaign /> },
  { path: "/image/list", Component: <GridImage /> },
  { path: "/image/create_new", Component: <GenImage /> },
  { path: `/image/edit/:image_id`, Component: <GenImage /> },

  { path: "/pricing/upgrade-plan", Component: <Pricing /> }
]

export default routes;