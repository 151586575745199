import { Link } from 'react-router-dom';
import { LI, UL } from '../../../../../AbstractElements';
import { useAppDispatch } from '../../../../../ReduxToolkit/Hooks';
import { Href } from '../../../../../utils/Constant';
import { HoverTemplateButtonsProp, TemplateType } from '../../../../../Types/PersonalVideo/TemplateList/TemplateList';

const HoverButtons = ({ item, setDataId, setOpenModal }: HoverTemplateButtonsProp) => {
    const dispatch = useAppDispatch();

    // const AddToCarts = (item: ProductItemInterface, quantity: number) => {
    //   dispatch(addToCartData({ item, quantity }));
    // };
  
    const onClickHandle = (i: TemplateType) => {
      setOpenModal(true);
      setDataId(i.id);
    };
    return (
      <div className="product-hover">
        <UL className="simple-list flex-row">
          <LI>
            <Link onClick={() => onClickHandle(item)} to={Href}>
              <i className="icon-eye"></i>
            </Link>
          </LI>
        </UL>
      </div>
    )
}

export default HoverButtons