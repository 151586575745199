import { Fragment, useEffect, useRef, useState } from "react";
import { Stage, Image, Layer, Text, Rect, Transformer, TextPath } from "react-konva";
import useImage from "use-image";
import FontPicker from "font-picker-react";
import { Badge, Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import { RgbaColorPicker } from "react-colorful";
import { Copy } from "react-feather";

interface ImageEditorProps {
  file: File | null | undefined;
  imgHeight: any;
  imgWidth: any;
  setTags: React.Dispatch<React.SetStateAction<any>>;
  setImgHeight: React.Dispatch<React.SetStateAction<any>>;
  setImgWidth: React.Dispatch<React.SetStateAction<any>>;
  tags?: any; // Add tags as a prop
}

interface ITextElement {
  id: any;
  isStatic: boolean;
  textPreview: string;
  dynamicValue: string;
  x: number;
  y: number;
  rotation: number;
  curvature: number;
  fontSize: number;
  fontFamily: string;
  color: any;
  trimCharacters: number;
}

interface TextElementProps {
  textProps: ITextElement;
  isSelected: boolean;
  onSelect: () => void;
  onUpdate: (props: ITextElement) => void;
}

const TextElement: React.FC<TextElementProps> = ({ textProps, isSelected, onSelect, onUpdate }) => {
  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);

  // Function to load font dynamically
  const loadFont = async (fontFamily: string) => {
    await document.fonts.load(`10pt "${fontFamily}"`);
  };

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  console.log(textProps)

  useEffect(() => {
    loadFont(textProps.fontFamily).then(() => {
      const node = shapeRef.current;
      if (node) {
        // Force redraw
        node.getLayer().batchDraw();
      }
    });
  }, [textProps.fontFamily]);

  return (
    <Fragment>
      <Text
        text={textProps.textPreview}
        x={textProps.x}
        y={textProps.y}
        fontSize={textProps.fontSize}
        fontFamily={textProps.fontFamily}
        fill={`rgba(${textProps.color.r}, ${textProps.color.g}, ${textProps.color.b}, ${textProps.color.a})`}
        draggable
        rotation={textProps.rotation}
        // Include scaleX and scaleY reset if transformation is applied
        scaleX={1}
        scaleY={1}
        offsetX={textProps.fontSize * textProps.textPreview.length / 4}
        offsetY={textProps.fontSize / 2}
        onMouseDown={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        onDragEnd={(e) => {
          onUpdate({
            ...textProps,
            x: Math.round(e.target.x()),
            y: Math.round(e.target.y()),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          onUpdate({
            ...textProps,
            x: Math.round(node.x()),
            y: Math.round(node.y()),
            rotation: node.rotation(),
            fontSize: Math.round(Math.max(5, node.fontSize() * node.scaleX()))
          });
          node.scaleX(1);
          node.scaleY(1);
        }}
      />

      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={true}
          // flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Fragment>
  );
};


const ImageEditor: React.FC<ImageEditorProps> = ({ file, setTags, imgHeight, imgWidth, setImgHeight, setImgWidth, tags = [] }) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [image] = useImage(imageUrl); // Dynamically load your image
  const [texts, setTexts] = useState<any>(tags);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const [isRotating, setIsRotating] = useState(false);
  const [rotationDirection, setRotationDirection] = useState('');

  const addText = (isStatic: boolean) => {
    const newText: ITextElement = {
      id: texts.length + 1,
      isStatic: isStatic,
      textPreview: "Default",
      dynamicValue: "firstName",
      x: imageWidth / 2,
      y: imageHeight / 2 - 10,
      rotation: 0,
      curvature: 0,
      fontSize: 40,
      trimCharacters: 0,
      fontFamily: "Open Sans",
      color: { r: 0, g: 107, b: 194, a: 1 }
    };
    setTexts([...texts, newText]);
    setTags([...texts, newText]);
  };

  const duplicateTextElement = (id: number) => {
    const textToDuplicate = texts.find((text: any) => text.id === id);
    if (textToDuplicate) {
      const newText = {
        ...textToDuplicate,
        id: texts.length + 1,
        x: textToDuplicate.x + 30,
        y: textToDuplicate.y + 30,
      };
      setTexts([...texts, newText]);
      setTags([...texts, newText]);
    }
  };

  const getSelectedText = () => {
    return texts.filter((text: any) => text.id === selectedId)[0];
  };

  const removeSelectedText = () => {
    const _texts = texts.filter((text: any) => text.id !== selectedId);
    setTexts(_texts);
    setTags(_texts);
    setSelectedId(null);
  };
  console.log(getSelectedText()?.color)

  const updateText = (id: number, key: string, value: any) => {
    const updatedTexts = texts.map((text: any) => {
      if (text.id === id) {
        return { ...text, [key]: value };
      }
      return text;
    });
    setTexts(updatedTexts);
    setTags(updatedTexts);
  };

  const updateTextPosition = (id: number, x: number, y: number) => {
    const updatedTexts = texts.map((text: any) => {
      if (text.id === id) {
        return { ...text, x, y };
      }
      return text;
    });
    setTexts(updatedTexts);
    setTags(updatedTexts);
  };

  useEffect(() => {
    setTexts(tags)
    if (file) {
      const url = URL.createObjectURL(file);
      setImageUrl(url);

      const img = new window.Image();
      img.onload = () => {
        const stageLayerRate = ((window.innerWidth - 300) * 0.7) / (window.innerHeight - 240);
        const imageLayerRate = img.width / img.height;
        if (!imgWidth) {
          if (stageLayerRate >= imageLayerRate) {
            const _height = Math.min(img.height, window.innerHeight - 240);
            setImageWidth(img.width * (_height / img.height));
            setImageHeight(_height);
            setImgWidth(img.width * (_height / img.height));
            setImgHeight(_height);
          } else {
            const _width = Math.min(img.width, (window.innerWidth - 300) * 0.7);
            setImageHeight(img.height * (_width / img.width));
            setImageWidth(_width);
            setImgHeight(img.height * (_width / img.width));
            setImgWidth(_width);
          }
        }
        else {
          setImageWidth(imgWidth)
          setImageHeight(imgHeight)
        }
      }

      img.src = url;

      // Clean up the URL when the component unmounts or file changes
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  console.log(imageWidth, imageHeight)
  const handleDragEnd = (e: any, id: number) => {
    const { x, y } = e.target.position();

    updateTextPosition(id, Math.round(x), Math.round(y));
  };

  useEffect(() => {
    let timer: any;
    if (isRotating) {
      timer = setInterval(() => {
        const currentRotation = getSelectedText()?.rotation; // Fetch inside interval
        handleRotate(rotationDirection, currentRotation);
      }, 100);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isRotating, rotationDirection, getSelectedText()?.rotation]);

  const handleRotate = (direction: any, currentRotation: any) => {
    if (selectedId !== null) {
      let newRotation;
      if (direction === 'left') {
        newRotation = currentRotation < 5 ? (currentRotation + 355) % 360 : (currentRotation - 5) % 360;
      } else if (direction === 'right') {
        newRotation = (currentRotation + 5) % 360;
      }
      updateText(selectedId, "rotation", newRotation);
    }
  };
  const startRotation = (direction: string) => {
    setRotationDirection(direction);
    setIsRotating(true);
  };

  const stopRotation = () => {
    setIsRotating(false);
  };

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };

  return (
    <Row>
      <Col lg={3}>
        <Card>
          <CardBody>
            <H5 className="mb-3"> Add Elements </H5>
            <Badge className="px-3 py-1" color="primary" onClick={() => addText(false)} style={{ cursor: "pointer" }}>
              <p className="mb-0 "><strong> {"< >"}</strong></p>
              <span className="mb-1">MERGE TAG</span>
            </Badge>
            <Badge className="px-3 py-1" color="primary" onClick={() => addText(true)} style={{ cursor: "pointer" }}>
              <p className="mb-0 "><strong> {"T"}</strong></p>
              <span className="mb-1">Text</span>
            </Badge>
          </CardBody>
        </Card>
        {selectedId && (
          <Card style={{ overflow: "auto", maxHeight: "calc(100vh - 320px)" }}>
            <CardBody>
              <H5 className="mb-3"> Element Properties </H5>
              {!getSelectedText()?.isStatic && <div className="mb-3">
                <Label className="col-form-label mb-0">{"Dynamic Value"}</Label>
                <Input
                  type="text"
                  onChange={(e) => updateText(selectedId, "dynamicValue", e.target.value)}
                  placeholder="Dynamic Text"
                  value={getSelectedText()?.dynamicValue || ""}
                  className="form-control"
                />
              </div>}
              <div className="mb-3">
                <Label className="col-form-label mb-0">{getSelectedText()?.isStatic ? "Text Value" : "Fallback Text"}</Label>
                <Input
                  type="text"
                  onChange={(e) => updateText(selectedId, "textPreview", e.target.value)}
                  placeholder="Text Preview"
                  value={getSelectedText()?.textPreview || ""}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <Label className="col-form-label mb-0">{"Font Size"}</Label>
                <Input
                  type="number"
                  onChange={(e) =>
                    updateText(selectedId, "fontSize", parseInt(e.target.value))
                  }
                  value={getSelectedText()?.fontSize || ""}
                  placeholder="Font Size"
                  className="form-control"
                />
              </div>
              {!getSelectedText()?.isStatic && <div className="mb-3">
                <Label className="col-form-label mb-0">{"Trim Characters"}</Label>
                <Input
                  type="number"
                  onChange={(e) => updateText(selectedId, "trimCharacters", e.target.value)}
                  placeholder="0"
                  value={getSelectedText()?.trimCharacters || ""}
                  className="form-control"
                />
              </div>}
              <div className="mb-3 font-family-picker">
                <Label className="col-form-label mb-0">{"Font Family"}</Label>
                <FontPicker
                  apiKey="AIzaSyCCbkVRwF3BqhbHQ8kou9VPUITlwOm_h-Q"
                  limit={200}
                  activeFontFamily={
                    getSelectedText()?.fontFamily || "Open Sans"
                  }
                  onChange={(nextFont: any) => {
                    updateText(selectedId, "fontFamily", nextFont.family);
                  }}
                />
              </div>
              <div className="mb-3">
                <Label className="col-form-label mb-0">{"Font Color"}</Label>

                <RgbaColorPicker color={getSelectedText()?.color} onChange={(e) => { console.log(e); updateText(selectedId, "color", e) }} />
                <div className="d-flex mb-3" style={{ gap: '4px' }}>
                  <div >
                    <Label className="col-form-label mb-0">{"Red"}</Label>
                    <Input
                      type="number"
                      onChange={(e) => updateText(selectedId, "color", { ...getSelectedText()?.color, r: parseFloat(e.target.value) > 255 ? 255 : parseFloat(e.target.value) < 0 ? 0 : e.target.value })}
                      placeholder="0"
                      value={getSelectedText()?.color.r || ""}
                      className="form-control" />
                  </div>
                  <div>
                    <Label className="col-form-label mb-0">{"Green"}</Label>
                    <Input
                      type="number"
                      onChange={(e) => updateText(selectedId, "color", { ...getSelectedText()?.color, g: parseFloat(e.target.value) > 255 ? 255 : parseFloat(e.target.value) < 0 ? 0 : e.target.value, })}
                      placeholder="0"
                      value={getSelectedText()?.color.g || ""}
                      className="form-control" />
                  </div>
                  <div>
                    <Label className="col-form-label mb-0">{"Blue"}</Label>
                    <Input
                      type="number"
                      onChange={(e) => updateText(selectedId, "color", { ...getSelectedText()?.color, b: parseFloat(e.target.value) > 255 ? 255 : parseFloat(e.target.value) < 0 ? 0 : e.target.value, })}
                      placeholder="0"
                      value={getSelectedText()?.color.b || ""}
                      className="form-control" />
                  </div>
                  <div>
                    <Label className="col-form-label mb-0">{"Opacity"}</Label>
                    <Input
                      type="number"
                      onChange={(e) => updateText(selectedId, "color", { ...getSelectedText()?.color, a: parseFloat(e.target.value) > 1 ? 1 : parseFloat(e.target.value) < 0 ? 0 : e.target.value, })}
                      placeholder="0"
                      value={getSelectedText()?.color.a || ""}
                      className="form-control" />
                  </div>
                </div>
              </div>
              <Btn className="py-2 d-flex align-items-center mb-3" style={{ gap: "4px" }} color="secondary" onClick={() => selectedId && duplicateTextElement(selectedId)}>
                <Copy width={16} height={16} /> Duplicate
              </Btn>
              <Btn color="danger" onClick={removeSelectedText}>{getSelectedText()?.isStatic ? "Remove Text" : "Remove Tag"}</Btn>

              {/* Add more controls as needed */}
            </CardBody>
          </Card>
        )}
      </Col>
      <Col lg={9}>
        <Card style={{ background: "#e9e9e9" }}>
          <CardBody
            className="d-flex align-items-center justify-content-center"
            style={{ overflow: "auto", maxWidth: "100%", minHeight: "calc(100vh - 200px)" }}
          >
            <Stage width={imageWidth} height={imageHeight} className="shadow" onMouseDown={checkDeselect} onTouchStart={checkDeselect}
            >
              <Layer>
                <Image image={image} height={imageHeight} width={imageWidth} />
                {texts.map((text: any, i: any) => (
                  <Fragment><TextElement
                    key={text.id}
                    textProps={text}
                    isSelected={text.id === selectedId}
                    onSelect={() => {
                      setSelectedId(text.id);
                    }}
                    onUpdate={(newAttrs) => {
                      const updatedTexts = texts.map((t: any) => t.id === text.id ? newAttrs : t);
                      setTexts(updatedTexts);
                      setTags(updatedTexts); // Assuming this is needed for syncing state outside the component
                    }}
                  />
                  </Fragment>
                ))}
              </Layer>
            </Stage>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ImageEditor;
