import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const login = localStorage.getItem("token")
  return login ? (
    <Outlet />
  ) : (
    <Navigate to={`/login`} />
  );
};

export default PrivateRoute;
