import { Col, Container, Row } from "reactstrap";
import { P, SVG } from "../../AbstractElements";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row>
          <Col md="12" className="footer-copyright d-flex flex-wrap align-items-center justify-content-between">
            <P className="mb-0 f-w-600">
              Copyright 2024 © BePersonal AI

              
            </P>
            <P className="mb-0 f-w-600">
               Contact - <a href="mailto:hey@bepersonal.ai">hey@bepersonal.ai</a>
              {/* <SVG iconId='footer-heart' className="footer-icon" /> */}
            </P>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
