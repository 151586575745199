import { Link } from "react-router-dom";
import { Btn, P, SVG } from "../../AbstractElements";
import { Href } from "../../utils/Constant";
import { TableColumn } from "react-data-table-component";
import { CampaignListTableDataColumnType, CampaignListTableCampaign } from "../../Types/Campaign/CampaignType";
export const campaignListTableData: CampaignListTableDataColumnType[] = [
  {
    name: "Demo Campaign",
    sent: 3,
    viewed: 2,
    ignored: 1,
  },
];

const CampaignListTableManage = () => {
  return (
    <div className="product-action">
      <Link to={Href}>
        <SVG iconId="edit-content" />
      </Link>
      <SVG iconId="trash1" />
    </div>
  );
};

const CampaignListTableAction = () => {
  return (
    <div className="product-action">
      <Btn color="success" className="px-2 py-1" onClick={() => { }}>{"Send"}</Btn>

    </div>
  );
};


const CampaignListTableCampaignName: React.FC<CampaignListTableCampaign> = ({ name }) => {
  return (
    <div className="product-names my-2">
      <P>{name}</P>
    </div>
  );
};


export const campaignListTableDataColumn: TableColumn<CampaignListTableDataColumnType>[] = [
  {
    name: "Campaign Name",
    cell: (row) => <CampaignListTableCampaignName name={row.name} />,
    sortable: true,
    grow: 2,
  },
  {
    name: "Sent",
    selector: (row) => `${row.sent}`,
    sortable: true,
  },
  {
    name: "Viewed",
    selector: (row) => `${row.viewed}`,
    sortable: true,
  },
  {
    name: "Ignored",
    selector: (row) => `${row.ignored}`,
    sortable: true,
  },

  {
    name: "Manage",
    cell: () => <CampaignListTableManage />,
  },

  {
    name: "Action",
    cell: () => <CampaignListTableAction />,
  },
];

