// src/Components/PersonalImage/GridImageContainer.js
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../ReduxToolkit/Hooks';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { getPersonalImageListAPI } from '../../../Api/image';
import { setImages } from '../../../ReduxToolkit/Reducers/PersonalImageSlice';
import ImageGrid from './ImageGrid';
import { Btn, H4 } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import SubscribeAlert from '../../Pricing/Alert';

const GridImageContainer = () => {
  const { sideBarOn } = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useAppSelector(state => state.user);

  const getImageList = async () => {
    const images = await getPersonalImageListAPI();
    dispatch(setImages(images));
  };


  useEffect(() => {
    if (!loggedIn) return;
    getImageList();
  }, [loggedIn]);

  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          <SubscribeAlert />
          <Card>
            <CardBody className="gallery my-gallery mb-0">
              <Row className='pb-3'>
                <Col md={6}>
                  <H4> {"Personalized Images"}</H4>
                </Col>
                <Col md={6} className='d-flex justify-content-end'>
                  <Btn color="success" className="ms-3" style={{ minWidth: "160px" }} onClick={() => {
                    navigate("/image/create_new");
                  }}>{"New Image"}</Btn>
                </Col>
              </Row>
              <ImageGrid />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GridImageContainer;
