import { Alert, Col, Container, Row } from 'reactstrap'
import BecomeMember from './BecomeMember'
import { useAppSelector } from '../../ReduxToolkit/Hooks'
import { Link } from 'react-router-dom'
import { H4, H5 } from '../../AbstractElements'

const SubscribeAlert = () => {
  const user = useAppSelector(state => state.user)
  return (
    user.active_images_limit <= user.personalized_images_count && user.active_images_limit === 1 ?
      <Container fluid>
        <Row>
          <Col sm="12">
            <Alert color="danger" >
              <H4 className='text-white'>You already reached out the limitation.
                Please Click <Link to="/pricing/upgrade-plan" className='text-white text-underline' style={{ fontSize: '1.25rem' }}>HERE</Link> to upgrade your plan</H4>
            </Alert>
          </Col>
        </Row>
      </Container> : <></>
  )
}

export default SubscribeAlert