import { H3 } from "../../../../AbstractElements";

const GeneratingLoader = () => {
  return (
    <>
      <div className="loader-wrapper" style={{width:"calc(100% - 252px)", background:"rgba(0,0,0,0.2)", marginLeft:"-27px"}}>
        <div>
          <div className="loader loader-1">
            <div className="loader-outter" />
            <div className="loader-inner" />
            <div className="loader-inner-1" />
          </div>
          <H3 className="text-center">Generating...</H3>
        </div>
      </div>

    </>
  );
};

export default GeneratingLoader;
