import TemplatesContainer from '../../../../Component/PersonalVideo/Template/TemplateList/TemplateList'

const TemplateList = () => {
  return (
    <div className='page-body'>
      <TemplatesContainer />
    </div>
  )
}

export default TemplateList