import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../ReduxToolkit/Hooks';
import { Form, Formik } from 'formik';
import { Fragment, useEffect, useState } from 'react';
import { Btn, P } from '../../../AbstractElements';
import { templateValidation, templateInitialValue } from '../../../Data/PersonalVideo/TemplateList/TemplateList';
import UploadImage from './UploadImage';
import ImageEditor from './ImageEditor';
import { toast } from 'react-toastify';
import { getPersonalImage, storeImageAPI, updateImageAPI } from '../../../Api/image';
import { setGenerating } from '../../../ReduxToolkit/Reducers/PersonalImageSlice';
import GeneratingLoader from '../../PersonalVideo/GenVideo/VideoCreate/GeneratingLoader';

const base64ToBlob = (base64: any, mimeType: any) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

const PersonalImageForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { image_id } = useParams();
  const [isUploaded, setIsUploaded] = useState(image_id ? true : false);
  const [file, setFile] = useState<File | null>(null);
  const [tags, setTags] = useState<any>([]);
  const [imgWidth, setImgWidth] = useState<any>();
  const [imgHeight, setImgHeight] = useState<any>()
  const { generating } = useAppSelector(state => state.personalImages);

  const storeImage = async () => {
    if (!file || !imgWidth || !imgHeight) {
      toast.error("Please upload an image and set dimensions.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('tags', JSON.stringify(tags));
    formData.append('img_width', imgWidth.toString());
    formData.append('img_height', imgHeight.toString());

    try {
      dispatch(setGenerating(true));
      const response = image_id ? await updateImageAPI(image_id, formData) : await storeImageAPI(formData)
      dispatch(setGenerating(false));

      if (response?.data?.status === "success") {
        toast.success(image_id ? "Image updated successfully!" : "Image saved successfully!");
        image_id ? window.location.href = '/image/list ' : navigate("/image/list")
      } else {
        toast.error("Failed to save image.");
      }
    } catch (error) {
      console.error("Error saving image:", error);
      toast.error("An error occurred while saving the image.");
    }
  };

  useEffect(() => {
    if (image_id) {
      const fetchImage = async () => {
        try {
          const fetchedImage = await getPersonalImage(image_id);
          setImgWidth(fetchedImage.img_width);
          setImgHeight(fetchedImage.img_height);
          setTags(fetchedImage.tags);

          const mimeType = 'image/jpeg'; // Adjust the MIME type as necessary
          const blob = base64ToBlob(fetchedImage.image_data, mimeType);
          const file = new File([blob], fetchedImage.filename, { type: mimeType });
          setFile(file);
        } catch (error) {
          toast.error('Failed to fetch image');
        }
      };

      fetchImage();
    }
  }, [image_id]);

  return (
    <Fragment>
      {generating && <GeneratingLoader />}

      <Formik initialValues={templateInitialValue} validationSchema={templateValidation} onSubmit={() => { }}>
        {() => (
          <Form className="theme-form mt-4">
            {(file || image_id) && isUploaded &&
              <Fragment>
                <div className='d-flex justify-content-end'>
                  {image_id && <Btn className='mb-3 me-3' color='secondary' onClick={() => { setIsUploaded(false) }}>Change Background</Btn>
                  }
                  <Btn className='mb-3' color='success' onClick={storeImage}>Save Image</Btn>

                </div>
                <ImageEditor file={file} setTags={setTags} imgWidth={imgWidth} imgHeight={imgHeight} setImgWidth={setImgWidth} setImgHeight={setImgHeight} tags={tags} />
              </Fragment>
            }
            {((!file) || (image_id && !isUploaded)) &&
              <UploadImage file={file} setFile={setFile} isUploaded={isUploaded} setIsUploaded={setIsUploaded} />
            }
            {(image_id && !isUploaded) && <div className='d-flex justify-content-end'><Btn className='mb-3 me-3' color='danger' onClick={() => { setIsUploaded(true) }}>Cancel Change</Btn></div>}

          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default PersonalImageForm;
