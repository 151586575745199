import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { Form, Formik } from 'formik';
import ButtonSection from './ButtonSection';
import VariableSection from './VariableSection';
import { GenVideoFormData, SingleVariableType } from '../../../../Types/PersonalVideo/GenVideo/GenVideo';
import { genVideoInitialValue, genVideoValidation } from '../../../../Data/PersonalVideo/GenVideo/GenVideo';
import { useState } from 'react';
import SelectTemplateSection from './SelectTemplateSection';
import { generateVideoAPI } from '../../../../Api/video';
import { setGenerating } from '../../../../ReduxToolkit/Reducers/GenVideoSlice';
import GeneratingLoader from './GeneratingLoader';

const VideoCreateForm = () => {
  const navigate = useNavigate();
  const [variables, setVariables] = useState<SingleVariableType[]>([]);
  const dispatch = useAppDispatch();
  const { generating } = useAppSelector(state => state.video);

  const generateVideo = async (values: GenVideoFormData) => {
    if (variables.length && values.template) {
      dispatch(setGenerating(true));
      await generateVideoAPI({
        template_id: values.template,
        variables: variables
      });

      dispatch(setGenerating(false));
      navigate("/video/list");
    }
  };
  return (
    <>
      {generating && <GeneratingLoader />}

      <Formik initialValues={genVideoInitialValue} validationSchema={genVideoValidation} onSubmit={generateVideo}>
        {() => (
          <Form className="theme-form">
            <VariableSection variables={variables} setVariables={setVariables} />
            <SelectTemplateSection />
            <ButtonSection />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default VideoCreateForm