import TemplateUploadContainer from "../../../../Component/PersonalVideo/Template/UploadTemplate/UploadTemplate"

const UploadTemplate = () => {
  return (
    <div className='page-body'>
      <TemplateUploadContainer />
    </div>
  )
}

export default UploadTemplate