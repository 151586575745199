import * as Yup from "yup";
import { GenVideoFormData, GeneVideoType } from "../../../Types/PersonalVideo/GenVideo/GenVideo";

export const genVideoData: GeneVideoType[] = [

]
export const genVideoInitialValue: GenVideoFormData = {
    variables: [],
    template: ""
};

export const genVideoValidation = Yup.object().shape({
    template: Yup.string().required("Template is required"),
});