

import axios from "axios";

export const storeImageAPI = async (payload: any) => {
  try {
    const response = await axios.post('/image/store_image', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.data && response.data.status === "success") {
      return response;
    } else {
      console.error('Storing image failed with status:', response);
    }
  } catch (err) {
    console.error('Error during uploading template :', err);
    throw err;  // Optionally re-throw to handle the error in the component
  }
}

export const getPersonalImageListAPI = async () => {
  try {
    const response = await axios.get('/image/generated/list');

    if (response.data && response.data.status === "success") {
      return response.data.payload.images;
    }
  } catch (error) {
    console.error("Error fetching personal images:", error);
    return [];
  }
};

export const getPersonalImage = async (imageId: any) => {
  try {
    const response = await axios.get(`/image/${imageId}`);
    if (response.data && response.data.status === "success") {
      return response.data.payload;
    }

  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
};

export const deletePersonalImageAction = async (imageId: any) => {
  try {
    const response = await axios.delete(`/image/delete/${imageId}`)
    if (response.data && response.data.status === "success") {
      return response;
    }
  }
  catch (error) {
    console.error("Error fetching personal images:", error);
  }
};

export const updateImageAPI = async (imageId: string, payload: any) => {
  try {
    const response = await axios.post(`/image/update/${imageId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data && response.data.status === "success") {
      return response;
    } else {
      console.error('Updating image failed with status:', response);
    }
  } catch (err) {
    console.error('Error during updating image :', err);
    throw err;  // Optionally re-throw to handle the error in the component
  }
};