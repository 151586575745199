// src/ReduxToolkit/Reducers/PersonalImageSlice.js
import { createSlice } from '@reduxjs/toolkit';

interface initialStateInterface {
  images: Array<any>,
  generating: boolean
}

const initialState: initialStateInterface = {
  images: [],
  generating: false,
};

const PersonalImageSlice = createSlice({
  name: 'personalImages',
  initialState,
  reducers: {
    setImages(state, action) {
      state.images = action.payload;
    },
    setGenerating: (state, action) => {
      state.generating = action.payload
  }
  },
});

export const { setImages, setGenerating } = PersonalImageSlice.actions;
export default PersonalImageSlice.reducer;