import DashboardContainer from '../../Component/Dashboard'

const Dashboard = () => {
  return (
    <div className='page-body'>
      <DashboardContainer />
    </div>
  )
}

export default Dashboard