import { Card, CardBody, Col, Row } from "reactstrap";
import CommonCardHeader from "../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { ProjectStatusHeading } from "../../../utils/Constant";
import { H6, Image, P } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";
import { projectStatusData } from "../../../Data/Dashboard/ProjectData";
import { useEffect, useState } from "react";
import { getOwnStatsAction } from "../../../Api/auth";
import { toast } from "react-toastify";

const ImageStatus = () => {
  const [totalImgages, setTotalImages] = useState(0);
  const [tagsUsed, setTagsUsed] = useState(0);

  const getOwnStats = async () => {
    try {
      const response = await getOwnStatsAction();
      if (response.status === 'success') {
        setTotalImages(response.payload.personalized_images_count)
        setTagsUsed(response.payload.total_views)
      }
      else {
        if (response.response)
          toast.error(response.response.data.message)
        else
          toast.error("Get user stats Error!")
      }
    } catch (error) {
      toast.error("Get user stats failed!");
    }
  }

  useEffect(() => {
    getOwnStats()
  })
  return (
    <Col xxl="3" xl="3" className="">
      <Card>
        <CommonCardHeader headClass="card-no-border pb-0" title={"Image Status"} />
        <CardBody className="project-status-col">
          <Row>
            <Col xxl="6">
              <div className={`btn-light1-warning b-r-10`}>
                <div className={`upcoming-box`}>
                  <div className={`upcoming-icon bg-warning`}>
                    <Image src={dynamicImage(`dashboard-2/svg-icon/processing.png`)} alt="icons" />
                  </div>
                  <H6>{"Total Images"}</H6>
                  <P>{totalImgages + " images"}</P>
                </div>
              </div>
            </Col>
            <Col xxl="6">
              <div className={`btn-light1-secondary b-r-10`}>
                <div className={`upcoming-box`}>
                  <div className={`upcoming-icon bg-secondary`}>
                    <Image src={dynamicImage(`dashboard-2/svg-icon/check.png`)} alt="icons" />
                  </div>
                  <H6>{"Tags used"}</H6>
                  <P>{tagsUsed + " times"}</P>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ImageStatus;
