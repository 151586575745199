import CampaignListContainer from '../../../Component/Campaign/CampaignList/CampaignList'

const CampaignList = () => {
  return (
    <div className='page-body'>
      <CampaignListContainer />
    </div>
  )
}

export default CampaignList