import axios from "axios";

export const generateVideoAPI = async (payload: any) => {
    try {
        const response = await axios.post('/video/generate_personal', payload,
        );
        if (response.data && response.data.status === "success") {
            return response.data.payload.video;  // This now correctly returns the template object
        } else {
            console.error('Generating Video failed with status:', response);

        }
    } catch (err) {
        console.error('Error during Generating Video:', err);
        throw err;  // Optionally re-throw to handle the error in the component
    }
}

export const getVideoListAPI = async () => {
    try {
        const response = await axios.get('/video/generated/list');
        if (response.data) {
            if (response.data.status === "success") {
                return response.data.payload.videos;  // This now correctly returns the template object
            }
        } else {
            console.error('Error during fetching Video:', response);
        }
    } catch (err) {
        console.error('Error during fetching Video:', err);
        throw err;  // Optionally re-throw to handle the error in the component
    }
}
