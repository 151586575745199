import { ErrorMessage, Field } from 'formik';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { TemplateType } from '../../../../Types/PersonalVideo/TemplateList/TemplateList';

const SelectTemplateSection = () => {
  const templates = useAppSelector(state => state.template.templateList)
  return (
    <Row>
      <Col sm="12">
        <FormGroup>
          <Label check>{"Choose File"}</Label>
          <Field as="select" name="template" className="form-control form-select">
            <option disabled value="">{"--Please choose a template--"}</option>
            {templates && templates.map((template: TemplateType, index: number) => (
              <option value={template.id} key={index}>{template.name}</option>
            ))}
          </Field>
          <ErrorMessage name="template" component="span" className="text-danger" />
        </FormGroup>
      </Col>

    </Row>
  );
}

export default SelectTemplateSection