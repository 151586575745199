import axios from "axios";

export const uploadThumbnailAPI = async (payload: any) => {
    try {
        const response = await axios.post('/video/upload_thumbnail', payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        if (response.data && response.data.status === "success") {
            return response.data.payload.filename;  // This now correctly returns the template filename
        } else {
            console.error('Uploading template failed with status:', response);

        }
    } catch (err) {
        console.error('Error during uploading template :', err);
        throw err;  // Optionally re-throw to handle the error in the component
    }
}

export const uploadTemplateAPI = async (payload: any) => {
    try {
        const response = await axios.post('/video/upload_template', payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        if (response.data && response.data.status === "success") {
            return response.data.payload.filename;  // This now correctly returns the template filename
        } else {
            console.error('Uploading template failed with status:', response);

        }
    } catch (err) {
        console.error('Error during uploading template :', err);
        throw err;  // Optionally re-throw to handle the error in the component
    }
}

export const createTemplateAPI = async (payload: any) => {
    try {
        const response = await axios.post('/video/create_template', payload,
        );
        if (response.data) {
            if (response.data.status === "success") {
                return response.data.payload.video;  // This now correctly returns the template object
            }
        } else {
            console.error('Creating template model with status:', response);

        }
    } catch (err) {
        console.error('Error during creating template model:', err);
        throw err;  // Optionally re-throw to handle the error in the component
    }
}

export const getTemplateAPI = async () => {
    try {
        const response = await axios.get('video/template/list');
        if (response.data) {
            if (response.data.status === "success") {
                return response.data.payload.videos;  // This now correctly returns the template object
            }
        }
        else {
            console.error('Error during requesting template list:', response);
        }
    } catch (err) {
        console.error('Error during requesting template list:', err);
        throw err;  // Optionally re-throw to handle the error in the component
    }
}
