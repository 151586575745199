import { createSlice } from "@reduxjs/toolkit";
import { TemplateStateType } from "../../Types/PersonalVideo/TemplateList/TemplateList";


const initialState: TemplateStateType = {
  activeTab: "1",
  templateList: [],
};

const TemplateSlice = createSlice({
  name: "SideBarSlice",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setTemplate: (state, action) => {
      state.templateList = action.payload
    },
    addCreatedTemplate: (state, action) => {
      state.templateList = [...state.templateList, action.payload];
    },
  },
});
export const { setActiveTab, addCreatedTemplate, setTemplate } = TemplateSlice.actions;

export default TemplateSlice.reducer;