import { useAppSelector } from '../../../../../ReduxToolkit/Hooks';
import { Card, Row } from 'reactstrap';

import ReactPlayer from 'react-player'
import { GeneVideoType } from '../../../../../Types/PersonalVideo/GenVideo/GenVideo';
import { SERVER_URL } from '../../../../../utils/Constant';
import { Btn } from '../../../../../AbstractElements';
import { useEffect, useState } from 'react';

const ProductGrid = () => {
  const { videos } = useAppSelector((state) => state.video);
  const { listView, colClass } = useAppSelector((state) => state.filterData);
  const [isCopied, setIsCopied] = useState(false);
  const [currentVideo, setCurrentVideo] = useState<any>(null);

  const copyEmbedCode = (video: any) => {
    setIsCopied(true);
    setCurrentVideo(video);
    setTimeout(() => {
      setIsCopied(false);
      setCurrentVideo(null);
    }, 2500)
  }

  useEffect(() => {
    if (isCopied) {
      navigator.clipboard.writeText(`<a href="${SERVER_URL}/api/video/display/${currentVideo.filename}"><img src="${SERVER_URL}/api/video/thumbnail/${currentVideo.template.thumbnail}" width="500" height="auto" /></a>`);
    }
  }, [isCopied])

  return (
    <div className={`product-wrapper-grid`}>
      <Row>
        {(videos && videos.length) > 0 &&
          videos.map((video: GeneVideoType, index: number) => {
            return (
              <div id="gridId" className={`${colClass} ${listView ? "col-xl-12" : ""}`} key={index}>
                <Card>
                  <div className="product-box">
                    <div className="product-img">
                      <ReactPlayer
                        width={"100%"}
                        url={`${SERVER_URL}/api/video/display/${video.filename}`}
                        controls
                      />
                    </div>
                    <div className="p-3">
                      <Btn
                        color="success"
                        type='button'
                        onClick={() => copyEmbedCode(video)}
                      >
                        {(isCopied && video === currentVideo) ? 'Copied' : 'Copy'}
                      </Btn>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
      </Row>
    </div>
  )
}

export default ProductGrid