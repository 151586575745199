import { Card, CardBody, Col, Table } from "reactstrap";
import CommonCardHeader from "../../../CommonElements/CommonCardHeader/CommonCardHeader";
import { useEffect, useState } from "react";
import { getUserListAction } from "../../../Api/auth";
import { toast } from "react-toastify";
import UsersTableBody from "./UsersTableBody";

const UsersTable = () => {
  const [users, setUsers] = useState<any>([]);

  const getUsers = async () => {
    try {
      const response = await getUserListAction();
      if (response.status === 'success') {
        setUsers(response.payload)
      }
      else {
        if (response.response)
          toast.error(response.response.data.message)
        else
          toast.error("Get user list Error!")
      }
    } catch (error) {
      toast.error("Get user list failed!");
    }
  }
  useEffect(() => {
    getUsers()
  }, [])
  return (
    <Col xl="9"className="">
      <Card>
        <CommonCardHeader headClass="card-no-border pb-0" title={"Users"}/>
        <CardBody className="pt-0 projects px-0">
          <div className="dataTables_wrapper">
            <div className="table-responsive theme-scrollbar">
              <Table className="table display dataTable no-footer" id="selling-product">
                <thead>
                  <tr>
                    <th>
                      {"No"}
                    </th>
                    <th>{"First Name"}</th>
                    <th>{"Last Name"}</th>
                    <th>{"Joined at"}</th>
                    <th>{"email"}</th>
                    <th>{"Total images"}</th>
                    <th>{"Tags used"}</th>
                    <th>{"Website"}</th>
                  </tr>
                </thead>
                <UsersTableBody users={users} />
              </Table>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UsersTable;
