import GridImageContainer from "../../../Component/PersonalImage/GridImage/Image"

const GridImage = () => {
  return (
    <div className='page-body'>
      <GridImageContainer />
    </div>
  )
}

export default GridImage