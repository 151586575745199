import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../ReduxToolkit/Hooks';
import { Image, SVG, P, H3, H2 } from '../../AbstractElements';
import { handleResponsiveToggle, setToggleSidebar } from '../../ReduxToolkit/Reducers/LayoutSlice';
import { dynamicImage } from '../../Service';
import { FontSize } from '../../utils/Constant';

const LogoWrapper = () => {
  const dispatch = useAppDispatch();
  const { toggleSidebar } = useAppSelector((state) => state.layout)
  const { sidebarIconType } = useAppSelector((state) => state.themeCustomizer)

  return (
    <>
      <div className="logo-wrapper">
        <Link className='d-flex align-items-center' to={`/`}>
          <h2 className='ms-1 text-primary' style={{ fontSize: '24px' }}>BePersonal AI</h2>
        </Link>
        <div className="back-btn" onClick={() => dispatch(handleResponsiveToggle())}>
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="toggle-sidebar">
          <SVG className={`${sidebarIconType}-icon sidebar-toggle status_toggle middle`} iconId={`${sidebarIconType === "fill" ? "fill-" : ""}toggle-icon`} onClick={() => dispatch(setToggleSidebar(!toggleSidebar))} />
        </div>
      </div>
      <div className="logo-icon-wrapper">
        <Link to={`/`}>
          <Image className="img-fluid" src={dynamicImage("logo/logo-small.png")} alt="logo" />
        </Link>
      </div>
    </>
  );
}

export default LogoWrapper