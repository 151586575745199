import CreateCampaignContainer from "../../../Component/Campaign/CreateCampaign/CreateCampaign"

const CreateCampaign = () => {
  return (
    <div className='page-body'>
      <CreateCampaignContainer />
    </div>
  )
}

export default CreateCampaign