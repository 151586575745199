import { createSlice } from "@reduxjs/toolkit";
import { bookMarkData } from "../../Data/LayoutData/HeaderData";
import { UserType } from "../../Types/Auth/UserType";
const initialState: UserType = {
  firstname: "",
  lastname: "",
  email: "",
  loggedIn: false,
  personalized_images_count: 0,
  active_images_limit: 1,
  expire_date: null,
  tags_used: 0,
  total_views: 0
};

const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.firstname = action.payload.firstname || state.firstname;
      state.lastname = action.payload.lastname || state.lastname;
      state.email = action.payload.email || state.email;
      state.loggedIn = true;
      state.personalized_images_count = action.payload.personalized_images_count || state.personalized_images_count
      state.active_images_limit = action.payload.active_images_limit || state.active_images_limit
      state.expire_date = action.payload.expire_date || state.expire_date; // Update expire_date
      state.tags_used = action.payload.tags_used || state.tags_used
      state.total_views = action.payload.total_views || state.total_views
    },
    removeUser: (state) => {
      state.firstname = ""
      state.lastname = ""
      state.email = ""
      state.loggedIn = false
      state.personalized_images_count = 0;
      state.active_images_limit = 1;
      state.expire_date = null; // Reset expire_date
      state.tags_used = 0
      state.total_views = 0
    }
  },
});

export const { setUser, removeUser } = UserSlice.actions;

export default UserSlice.reducer;