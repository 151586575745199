import React, { Fragment, forwardRef } from "react";
import { ListGroupItem, ListGroupItemProps } from "reactstrap";
import { CSSModule } from "reactstrap/types/lib/utils";

interface PropsTypes extends ListGroupItemProps {
  children?: React.ReactNode;
  val?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (key: any) => void;
  tag?: React.ElementType;
  href?: string;
  style?: {
    listStyle?: string | undefined;
    display?: string | undefined;
    paddingBottom?: number;
    alignItems?: string | undefined;
    borderLeft?: string | undefined;
    borderRight?: string;
    padding?: string;
  } | CSSModule;
  title?: string;
}

const LI = forwardRef<HTMLLIElement, PropsTypes>((props, ref) => {
  const { children, val, ...rest } = props;
  return (
    <Fragment>
      <ListGroupItem {...rest} innerref={ref}>
        {val ? <div dangerouslySetInnerHTML={{ __html: val }} /> : ""}
        {children}
      </ListGroupItem>
    </Fragment>
  );
});

export default LI;
