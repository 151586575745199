import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../ReduxToolkit/Hooks';
import { Container } from 'reactstrap';
import GenVideoGrid from './GenVideoGrid/GenVideoGrid';
import { getVideoListAPI } from '../../../../Api/video';
import { setVideos } from '../../../../ReduxToolkit/Reducers/GenVideoSlice';

const GenVideosContainer = () => {
  const { sideBarOn } = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const { loggedIn } = useAppSelector(state => state.user);

  const getVideoList = async () => {
    const videos = await getVideoListAPI();
    dispatch(setVideos(videos));
  }

  useEffect(() => {
    if (!loggedIn) return;
    getVideoList();
  }, [loggedIn]);

  return (
    <Container fluid className={`product-wrapper ${sideBarOn ? "sidebaron" : ""}`}>
      <div className="product-grid">
        <GenVideoGrid />
      </div>
    </Container>
  )
}

export default GenVideosContainer