import { ErrorMessage, Field } from 'formik'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { ClientName, ClientPlaceholder, ProjectNamePlaceholder, ProjectTitle } from '../../../../utils/Constant'
import { Btn } from '../../../../AbstractElements'
import { useState } from 'react';
import { SingleVariableType } from '../../../../Types/PersonalVideo/GenVideo/GenVideo';

interface VariableSectionProps {
  variables: SingleVariableType[];
  setVariables: React.Dispatch<React.SetStateAction<SingleVariableType[]>>;
}

const VariableSection = ({ variables, setVariables }: VariableSectionProps) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const addVariables = () => {
    if (key && value) {
      setVariables([...variables, { key, value }])
      setKey("")
      setValue("")
    }
  }

  return (
    <>
      <Row>
        <Col lg={3}>
          <Label check>{"Key Variable"}</Label>
        </Col>
        <Col lg={3}>
          <Label check>{"Target Value"}</Label>
        </Col>
      </Row>

      {variables.map((variable, index) => (
        <Row key={index}>
          <Col lg={3}>
            <Label check>{variable.key}</Label>
          </Col>
          <Col lg={3}>
            <Label check>{variable.value}</Label>
          </Col>
        </Row>
      ))}
      <Row>
        <Col lg={3}>
          <FormGroup>
            <Field name="key" type="text" className="form-control" placeholder={"Eg: Name"} value={key} onChange={(event: any) => setKey(event.target.value)} />
            <ErrorMessage name="title" component="span" className="text-danger" />
          </FormGroup>
        </Col>
        <Col lg={3}>
          <FormGroup>
            <Field name="value" className="form-control" type="text" placeholder={"Eg: Michael"} value={value} onChange={(event: any) => setValue(event.target.value)} />
            <ErrorMessage name="client" component="span" className="text-danger" />
          </FormGroup>
        </Col>
      </Row>

      <Col sm={4}>
        <Btn color="primary" className="bg-light-primary mb-3" href={"#"} onClick={addVariables}>
          <i className="me-2 fa fa-plus"> </i>{"Add variables"}
        </Btn>
      </Col>
    </>
  )
}

export default VariableSection