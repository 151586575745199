import { Col, FormGroup, Label, Row } from 'reactstrap';
import { UploadProjectFiles } from '../../../../utils/Constant';
import { Dropzone, FileMosaic, ExtFile } from "@dropzone-ui/react";
import { H6, P } from '../../../../AbstractElements';
import { useEffect, useState } from 'react';
import { isVideoFile } from '../../../../utils';
interface UploadTemplateFileProps {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  isUploaded: boolean,
  setIsUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  setImageData: React.Dispatch<React.SetStateAction<string | null>>;
}

const UploadTemplateFile: React.FC<UploadTemplateFileProps> = ({ file, setFile, isUploaded, setIsUploaded, setImageData }) => {
  const [files, setFiles] = useState<ExtFile[]>([]);
  const [video, setVideo] = useState<any>(null);
  const updateFiles = (incomingFiles: ExtFile[]) => {
    setFiles(incomingFiles);
    setFile(incomingFiles.length > 0 && incomingFiles[0].file !== undefined ? incomingFiles[0].file : null);
    setIsUploaded(true);
  };

  const removeFile = (id: string | number | undefined) => {
    const _files = files.filter((x: ExtFile) => x.id !== id)
    setFiles(_files);
    setFile(_files.length > 0 && _files[0].file !== undefined ? _files[0].file : null);
    setVideo(null);
    setIsUploaded(false)
  };

  const getImageData = () => {
    const video: any = document.getElementById("element");
    if (video) {
      let canvas = document.createElement("canvas") as HTMLCanvasElement;
      let context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context?.drawImage(video, 0, 0);
      let url = canvas.toDataURL('image/png');
      setImageData(url)
    }
  }

  useEffect(() => {
    if (file) {
      const videoFile = Object.assign(file, { preview: URL.createObjectURL(file) });
      window.URL = window.URL || window.webkitURL;
      if (file) {
        const video = document.createElement('video');
        video.preload = "metadata";
        video.onloadedmetadata = evt => {
          setVideo(videoFile);
        }
        video.src = URL.createObjectURL(videoFile);
      }
    }
  }, [file]);

  return (
    <Row>
      <Col>
        <FormGroup>
          <Label check>{UploadProjectFiles}</Label>
          <Dropzone onChange={updateFiles} value={files} maxFiles={1} accept="video/*" header={false} footer={false} minHeight="80px" label="Drag'n drop files here or click to Browse">
            {files.map((file: ExtFile) => (
              <FileMosaic key={file.id} {...file} onDelete={removeFile} info={true} />
            ))}
            {files.length === 0 && (
              <div className="dz-message needsclick p-5">
                <i className="icon-cloud-up fs-1 text-primary"></i>
                <H6 className="f-w-700 mb-1">{"Upload Template Video"}</H6>
                <H6 className="note needsclick">
                  {" Drag&Drop or click here to upload your demo - Only video files"}
                </H6>
              </div>
            )}
          </Dropzone>
          {!isUploaded ?
            <P className='text-danger'>You must upload a template file</P> :
            file && !isVideoFile(file) && isUploaded ?
              <P className='text-danger'>You must upload a video filetype</P> :
              ""}

        </FormGroup>
      </Col>
      {video ?
        <video
          style={{
            visibility: "hidden",
            position: 'absolute'
          }}
          id="element"
          crossOrigin="anonymous"
          src={video?.preview}
          onLoadedData={() => {
            setTimeout(() => {
              getImageData()
            }, 500);
          }}></video> :
        null}
    </Row>
  );
}

export default UploadTemplateFile