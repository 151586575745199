import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { Card, CardBody } from "reactstrap";
import ButtonSection from "./ButtonSection";

interface CampaignTemplateEditorProps {
  onChange: React.Dispatch<React.SetStateAction<string>>;
}
const CampaignTemplateEditor = ({ onChange }: CampaignTemplateEditorProps) => {
  const editorRef = useRef(null);

  const handleEditorChange = (content: any) => {
    onChange(content)
  }

  return (
    <>

      <Editor
        onInit={(evt, editor: any) => editorRef.current = editor}
        // there's a known issue with how tinymce works where the intialValue and value
        // come into conflict when using useState. tinymce recommend removing initialValue
        // and setting the initial value as the the default state value i.e. formData.description
        // is set to the placeholder text instead of just an empty string
        // initialValue="<p>This is the initial content of the editor.</p>"
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        init={{
          height: 500,
          menubar: false,
          plugins: 'advlist autolink lists link image charmap preview anchor ' +
            'searchreplace visualblocks code fullscreen ' +
            'insertdatetime media table code help wordcount' + 'media mediaembed code lists'
          ,

          toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' + 'media code | ' +
            'removeformat help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}

        initialValue="<p>Hi there, this is a test email</p> <br/> <p> Best wishes </p>"
        onEditorChange={handleEditorChange}
      />

      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
}

export default CampaignTemplateEditor;