import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { Badges, Btn } from '../../../AbstractElements'
import { useState } from 'react';
import { SingleVariableType } from '../../../Types/PersonalVideo/GenVideo/GenVideo';

interface VariableSectionProps {
  variables: string[];
  setVariables: React.Dispatch<React.SetStateAction<string[]>>;
}

const VariableSection = ({ variables, setVariables }: VariableSectionProps) => {
  const [value, setValue] = useState("");
  const addVariables = () => {
    if (value) {
      setVariables([...variables, value])
      setValue("")
    }
  }

  return (
    <>
      <Card className='campaign-variables'>
        <CardBody>
          <Row>
            <Col lg={12}>
              <Label check>{"Key Variable"}</Label>
            </Col>
          </Row>

          {variables.map((variable, index) => (
            <Row className='mb-2' key={index}>
              <Col lg={12}>
                <Badges color="" className={`badge-light-secondary badge-bg`} >{'{{ ' + variable + ' }}'}</Badges>
              </Col>
            </Row>
          ))}
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Input name="variable" type="text" className="form-control" placeholder={"Eg: Name"} value={value} onChange={(event: any) => setValue(event.target.value)} />
              </FormGroup>
            </Col>
          </Row>

          <Col sm={12}>
            <Btn color="primary" className="bg-light-primary mb-3" href={"#"} onClick={addVariables}>
              <i className="me-2 fa fa-plus"> </i>{"Add variables"}
            </Btn>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}

export default VariableSection