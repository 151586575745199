import { ErrorMessage, Field } from 'formik'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import { ClientName, ClientPlaceholder, ProjectNamePlaceholder, ProjectTitle } from '../../../../utils/Constant'

interface TemplateNameSectionProps {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
}

const TemplateNameSection = () => {
  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label check>{"Video Name"}</Label>
            <Field name="name" type="text" className="form-control" placeholder={"Video Name"} />
            <ErrorMessage name="name" component="span" className="text-danger" />
          </FormGroup>
        </Col>
      </Row>

    </>
  )
};

export default TemplateNameSection;