import { Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import { UploadProjectFiles } from '../../../utils/Constant';
import { Dropzone, FileMosaic, ExtFile } from "@dropzone-ui/react";
import { H5, H6, P, SVG } from '../../../AbstractElements';
import { useState } from 'react';
import { isImageFile } from '../../../utils';

interface UploadImageProps {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  isUploaded: boolean,
  setIsUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes

const UploadImage: React.FC<UploadImageProps> = ({ file, setFile, isUploaded, setIsUploaded }) => {
  const [files, setFiles] = useState<ExtFile[]>([]);
  const [fileError, setFileError] = useState<string | null>(null);

  const updateFiles = (incomingFiles: ExtFile[]) => {
    const validFiles = incomingFiles.filter((file) => {
      if (file.file && file.file.size <= MAX_FILE_SIZE) {
        return true;
      } else {
        setFileError("File size must be less than 1MB");
        return false;
      }
    });

    if (validFiles.length > 0) {
      setFiles(validFiles);
      setFile(validFiles[0].file !== undefined ? validFiles[0].file : null);
      setIsUploaded(true);
      setFileError(null);
    } else {
      setFiles([]);
      setFile(null);
      setIsUploaded(false);
    }
  };

  const removeFile = (id: string | number | undefined) => {
    const _files = files.filter((x: ExtFile) => x.id !== id)
    setFiles(_files);
    setFile(_files.length > 0 && _files[0].file !== undefined ? _files[0].file : null);
    setIsUploaded(false);
    setFileError(null);
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <FormGroup>
              <Label check>{"Upload an image"}</Label>
              <Dropzone onChange={updateFiles} value={files} maxFiles={1} accept="image/*" header={false} footer={false} minHeight="80px" label="Drag'n drop files here or click to Browse">
                {files.map((file: ExtFile) => (
                  <FileMosaic key={file.id} {...file} onDelete={removeFile} info={true} />
                ))}
                {files.length === 0 && (
                  <div className="dz-message needsclick p-5">
                    <SVG iconId="file-upload" />
                    <H5>
                      Drag your image here, or
                      <span className="txt-primary ms-2">
                        Browse
                      </span>
                    </H5>
                    <span className="note needsclick">PNG, JPG or GIF</span>
                  </div>
                )}
              </Dropzone>
              {fileError && <P className='text-danger'>{fileError}</P>}
              {isUploaded &&
                file && !isImageFile(file) ?
                <P className='text-danger'>You must upload an image filetype</P> :
                ""}
            </FormGroup>
          </CardBody>
        </Card >
      </Col>
    </Row>
  );
}

export default UploadImage;
