// src/Components/PersonalImage/ImageGrid.js
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../ReduxToolkit/Hooks';
import { Row, Col, Card, CardBody, CardImg, Badge, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { SERVER_URL } from '../../../utils/Constant';
import { Check, Copy, Trash } from 'react-feather';
import { deletePersonalImageAction } from '../../../Api/image';
import { setImages } from '../../../ReduxToolkit/Reducers/PersonalImageSlice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


const ImageGrid = () => {
  const images = useAppSelector((state) => state.personalImages.images);
  const [copiedImageId, setCopiedImageId] = useState<string | null>(null);
  const dispatch = useAppDispatch()
  const [dropdownOpen, setDropdownOpen] = useState<any>({});

  const toggleDropdown = (imageId: any) => {
    setDropdownOpen((prevState: any) => ({
      ...prevState,
      [imageId]: !prevState[imageId]
    }));
  };

  const user = useAppSelector(state => state.user)
  const buildQueryString = (tags: any) => {
    const params = new URLSearchParams();
    const addedDynamicValues = new Set();

    tags.forEach((tag: any) => {
      if (!addedDynamicValues.has(tag.dynamicValue) && !tag.isStatic) {
        params.append(tag.dynamicValue, `{${tag.dynamicValue}}`);
        addedDynamicValues.add(tag.dynamicValue);
      }
    });

    return params.toString();
  };
  const handleDelete = async (id: string) => {
    const response = await deletePersonalImageAction(id)
    if (response?.data?.status === "success") {
      dispatch(setImages(images.filter(image => id !== image.id)))
      toast.success('Delete image successful')
    }

  }
  const copyEmbedCode = async (imageId: string, tags: any) => {
    if (user.tags_used < 1) {
      toast.error("You need to upgrade the plan to copy the embed code!");
      return;
    }
    const queryString = buildQueryString(tags);
    let embedCode = `<img src="${SERVER_URL}/api/image/${imageId}/view?${queryString}" alt="loading..." />`;

    // Decode the URL-encoded curly braces back to their original form
    embedCode = embedCode.replace(/%7B/g, '{{').replace(/%7D/g, '}}');

    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(embedCode);
        setCopiedImageId(imageId);
        setTimeout(() => setCopiedImageId(null), 3000); // Reset copied state after 3 seconds
      } catch (err) {
        console.error('Failed to copy embed code: ', err);
      }
    } else {
      // Fallback method for copying text if Clipboard API is not supported
      const textarea = document.createElement('textarea');
      textarea.value = embedCode;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        setCopiedImageId(imageId);
        setTimeout(() => setCopiedImageId(null), 3000); // Reset copied state after 3 seconds
      } catch (err) {
        console.error('Failed to copy embed code: ', err);
      }
      document.body.removeChild(textarea);
    }
  };

  return (
    <Row>
      {images.map((image: any) => (
        <Col key={image.id} sm="6" md="4" lg="3" className="mb-4">
          <Card>
            <CardBody>
              <div className='d-flex justify-content-end py-2'>
                <Badge
                  color="danger"
                  type="button"
                  onClick={() => handleDelete(image.id)}
                ><span className='d-flex'>
                    <p style={{ fontSize: 13 }}><Trash /></p>
                  </span></Badge>
              </div>
              <div className='overflow-hidden mb-3' style={{ maxHeight: 260 }} >
                <Link to={`/image/edit/${image.id}`}>
                  <CardImg top src={`${SERVER_URL}/api/image/${image.id}/view`} alt="Personalized Image" />
                </Link>
              </div>
              <p className=''>Views: {image.views}</p>
              <p>Created At: {new Date(image.created_at).toLocaleString()}</p>
              <div className="d-flex justify-content-between">
                {
                  copiedImageId !== image.id ?
                    <Dropdown isOpen={dropdownOpen[image.id] || false} toggle={() => toggleDropdown(image.id)}>
                      <DropdownToggle caret className='d-flex align-items-center embed-code'>
                        <p className='d-flex align-items-center' style={{ fontSize: 13 }}>
                          <Copy width={16} height={16} />
                          Embed Code</p>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => {
                          e.stopPropagation();
                          console.log(1);
                          copyEmbedCode(image.id, image.tags);
                        }}>
                          For Instantly
                        </DropdownItem>

                      </DropdownMenu>
                    </Dropdown>
                    :
                    <Badge
                      color="secondary"
                      type="button"

                    >
                      <span className='d-flex'>
                        <Check />
                        <p style={{ fontSize: 13 }}>Copied!</p>
                      </span>
                    </Badge>

                }
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ImageGrid;
