
import { H6 } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { Href } from "../../../utils/Constant";

interface UsersTableBodyProps {
  users: any
}

const UsersTableBody: React.FC<UsersTableBodyProps> = ({ users = [] }) => {
  return (
    <tbody>
      {users.map((user: any, i: any) => (
        <tr key={i}>
          <td>
            {i + 1}
          </td>
          <td>
            <div className="d-flex align-items-center">

              <div className="flex-grow-1 ms-2">
                <Link to={Href}>
                  <H6>{user.firstname}</H6>
                </Link>
              </div>
            </div>
          </td>
          <td className="project-dot">
            <div className="d-flex">
              <div className="flex-shrink-0">
                <span className={`bg-${user.color}`} />
              </div>
              <div className="flex-grow-1">
                <H6>{user.lastname}</H6>
              </div>
            </div>
          </td>
          <td> {user.created_at}</td>
          <td> {user.email}</td>
          <td>{user.personalized_images_count}</td>
          <td>{user.total_views}</td>
          <td className="text-center">
            {user.website}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default UsersTableBody;
