import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import PersonalImageForm from './PersonalImageForm'
import SubscribeAlert from '../../Pricing/Alert'
import { useAppSelector } from '../../../ReduxToolkit/Hooks'
import { useParams } from 'react-router-dom'

const GenerateImageContainer = () => {
  const user = useAppSelector(state => state.user)
  const { image_id } = useParams();

  return (
    <Container fluid>
      <Row>
        <Col sm="12">
          {!image_id && <SubscribeAlert />}
          {!image_id && user.active_images_limit <= user.personalized_images_count && user.active_images_limit === 1 ?
            "" : <PersonalImageForm />}
        </Col>
      </Row>
    </Container>
  )
}

export default GenerateImageContainer