import axios from 'axios';

export const createCheckoutSession = async (priceId: any) => {
  try {
    const response = await axios.post('/subscription/create-checkout-session', { price_id: priceId });
    return response.data.payload.checkout_url;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};


export const updatePlanAction = async (priceId: any) => {
  try {
    const response = await axios.post('/subscription/update-plan', { price_id: priceId });
    if (response.data && response.data.status === "success") {
      return response.data;
    }
  } catch (error) {
    console.error('Error downgrading plan:', error);
    throw error;
  }
}