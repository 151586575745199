import { Card, CardBody, Col } from "reactstrap";
import { H1, H3, P } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { GoPremium } from "../../../utils/Constant";
import { useAppSelector } from "../../../ReduxToolkit/Hooks";

const UserInfo = () => {
  const user = useAppSelector(state => state.user)

  
  return (
    <Col md={12} className="">
      <Card className="profile-greeting p-0">
        <CardBody>
          <Col xl={6}>
            <div className="img-overlay">
              <H1>{"Hey, " + user.firstname + " " + user.lastname + "!"}</H1>
              <H3>
                {" Welcome to BePersonal! "} <br />
              </H3>
              <H3 className="mt-4">
                {"We help you personalize images at scale and embed anywhere. "}
              </H3>
              <Link className="btn" to={`/image/create_new`}>
                {"Create an image"}
              </Link>

            </div>
          </Col>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UserInfo;
