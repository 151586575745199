import { Col, Row } from 'reactstrap'
import { Btn } from '../../../../AbstractElements'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../ReduxToolkit/Store'

const ButtonSection = () => {
  const { generating } = useSelector((state: RootState) => state.video);
  
  return (
    <Row>
      <Col>
        <div className="text-end">
          <Btn color="success" className="me-3" disabled={generating}>
            {"Generate a video"}
          </Btn>
        </div>
      </Col>
    </Row>
  )
}

export default ButtonSection